@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

:root {
	--title: #ffffff;
	--text: #ffffffb3;
	--body: #082032;
	--section: #1f3545;
	--white: #ffffff;
	--base: #19995e;
	--btn-hover: rgba(255, 255, 255, 0.1);
	--redish: #f563a9;
}

body {
	background: var(--body);
	font-family: "Inter", sans-serif;
	color: var(--text);
	line-height: 27.5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-weight: 700;
}

h1 {
	font-size: 42px;
}
h2 {
	font-size: 34px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
@include breakpoint(xl) {
	.container {
		max-width: 1316px;
	}
}
.bg-section {
	background: var(--section);
}
.bg-body {
	background: var(--body);
}
.partner-section {
	padding: 64px 0;
	@include breakpoint(max-md) {
		padding: 35px 0;
	}
}
.partner-img {
	height: 48px;
	object-fit: contain;
	margin: 0 75px;
	@include breakpoint(max-md) {
		margin: 0 35px;
	}
}
/*About Section*/
.about-content {
	font-size: 14px;
	.title {
		margin-bottom: 22px;
		font-weight: 800;
	}
	.text {
		font-size: 14px;
		font-weight: 300;
		max-width: 521px;
		margin-bottom: 30px;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		column-gap: 24px;
		max-width: 450px;
		row-gap: 16px;
		margin-bottom: 32px;
		li {
			width: 100%;
			max-width: 194px;
			display: flex;
			span {
				width: 0;
				flex-grow: 1;
				padding-left: 10px;
			}
		}
	}
	@include breakpoint(max-md) {
		.text {
			margin-bottom: 20px;
		}
		ul {
			row-gap: 8px;
			margin-bottom: 25px;
			@include breakpoint(max-sm) {
				row-gap: 4px;
			}
		}
	}
}
.btn-grp {
	display: flex;
	flex-wrap: wrap;
	row-gap: 16px;
	column-gap: 16px;
	@include breakpoint(sm) {
		column-gap: 28px;
	}
}
.cmn-btn {
	color: var(--white);
	padding: 13px 22px;
	display: flex;
	gap: 6px;
	align-items: center;
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-transform: capitalize;
	border-radius: 23px;
	background: var(--base);
	border: none;
	outline: none;
	box-shadow: none;
	transition: all ease 0.3s;
	text-align: center;
	&:hover {
		background: var(--btn-hover);
		color: var(--white);
	}
	@include breakpoint(max-sm) {
		padding: 10px 18px;
		font-weight: 600;
	}
	&.secondary-btn {
		background: var(--btn-hover);
		color: var(--white);
		&:hover {
			background: var(--base);
		}
	}
	&.btn-outline {
		background: transparent;
		border: 1px solid rgba(#cfdbd5, 0.15);
		&:hover {
			background: var(--btn-hover);
			border-color: var(--btn-hover);
			color: var(--white);
		}
	}
	&.icon-btn {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: 40px;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.about-section {
	padding: 100px 0 70px;
	@include breakpoint(xl) {
		padding: 156px 0 112px;
	}
}
.about-img {
	position: relative;
	.img1,
	.img2,
	.img3 {
		position: absolute;
		animation: trx 1.5s alternate infinite;
	}
	.img1 {
		left: 0;
		top: -30px;
		animation-delay: 0.5s;
	}
	.img2 {
		left: -63px;
		top: calc(100% - 80px);
		animation-delay: 1s;
	}
	.img3 {
		right: -50px;
		top: calc(50% - 33px);
		animation: trx2 1.5s alternate infinite;
	}
	@include breakpoint(max-sm) {
		max-width: 380px;
		margin: 0 auto;
		.img1,
		.img2,
		.img3 {
			width: 240px;
		}
	}
	@media (max-width: 400px) {
		.img1 {
			left: -10px;
		}
		.img2 {
			left: -33px;
		}
		.img3 {
			right: 0px;
		}
	}
}
@keyframes trx {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(30px);
	}
}
@keyframes trx2 {
	0% {
		transform: translateX(10px);
	}
	100% {
		transform: translateX(30px);
	}
}
.creators-section {
	padding: 62px 0;
}
.section-title {
	margin-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 14px;
	.title {
		margin: 0;
	}
}
.slide-prev,
.slide-next {
	cursor: pointer;
	svg {
		transition: all ease 0.3s;
		@include breakpoint(max-sm) {
			width: 38px;
			height: unset;
		}
	}
	&:hover {
		svg {
			fill: var(--base);
		}
	}
}
.nav-btns {
	display: flex;
	gap: 16px;
}
.creator-card {
	
	.creator-img {
		position: relative;
		border-radius: 15px 15px 0 0;
		overflow: hidden;
		
		img {
			width: 100%;
		}
		.chip {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 10.5px 20px 11.5px;
			position: absolute;
			border-radius: 23px;
			line-height: 24px;
			top: 10px;
			background: var(--body);
			color: var(--white);
			font-size: 14px;
			font-weight: 700;
		}
		.right-badge {
			right: 10px;
		}
		.left-badge {
			left: 10px;
		}
	}
	.col-lg-6{
		display:flex;
	width:440px;
	height:200px;
	}
	col-lg-6:img{
		min-width:0;
		flex:1 1 10px;
		opacity:0.5;
		transition:0.5s;
	}
		
	
		
	.col-lg-6:hover{
		flex:1 1 280px;
		opacity:1;
	                       
	}
	.creator-cont {
		padding: 14px 15px 20px;
		.name {
			margin-bottom: 16px;
			font-weight: 800;
			transition: all ease 0.3s;
			font-size: 18px;
			&:hover {
				color: var(--base);
			}
		}
		border-radius: 0 0 15px 15px;
		background: var(--body);
		border: 1px solid rgba(207, 219, 213, 0.15);
		border-top: none;
		.info {
			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 12px 0;
				&:not(:last-child) {
					border-bottom: 1px solid rgba(207, 219, 213, 0.15);
				}
				&:last-child {
					padding-bottom: 0;
				}
				span {
					opacity: 0.5;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
				}
			}
			h6 {
				font-size: 14px;
				line-height: 21px;
				text-align: right;
				margin: 0;
				small {
					opacity: 0.5;
					font-weight: 300;
					font-size: 12px;
					line-height: 18px;
				}
			}
		}
	}
	@media (max-width: 399px) {
		.creator-cont {
			.name {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 10px;
			}
			.info {
				li {
					padding: 9px 0;
					h6 {
						font-weight: 600;
						font-size: 13px;
					}
				}
			}
		}
		.creator-img {
			.chip {
				font-size: 12px;
				padding: 8px 15px;
			}
		}
	}
	@media (max-width: 1199px) and (min-width: 992px) {
		.creator-cont {
			.name {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 10px;
			}
			.info {
				li {
					padding: 9px 0;
					h6 {
						font-weight: 600;
						font-size: 13px;
					}
				}
			}
		}
		.creator-img {
			.chip {
				font-size: 12px;
				padding: 8px 15px;
			}
		}
	}
}
.client-slider,
.creator-slider {
	margin: 0 -12px;
	.slick-slide {
		padding: 0 12px;
	}
	.slick-dots {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: center;
		column-gap: 6px;
		row-gap: 4px;
		bottom: -28px;
		left: 48px;
		width: calc(100% - 96px);
		li {
			padding: 0;
			margin: 0;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: #ebf0f0;
			opacity: 0.1;
			transition: all ease 0.3s;
			padding: 0;
			margin: 0;
			border-radius: 4px;
			button {
				padding: 0;
				margin: 0;
				width: 8px;
				height: 8px;
				opacity: 0;
			}
			&.slick-active {
				opacity: 1;
				background: var(--base);
				width: 16px;
			}
		}
		@include breakpoint(max-sm) {
			left: 36px;
			width: calc(100% - 72px);
		}
	}
}
.client-slider {
	padding-bottom: 32px;
	.slick-dots {
		bottom: -60px;
		@include breakpoint(max-sm) {
			bottom: -54px;
		}
	}
}
.px-30 {
	padding-left: 30px;
	padding-right: 30px;
}
.customer-section {
	padding: 75px 0;
	@include breakpoint(xl) {
		padding: 100px 0 125px;
	}
}
.client-card {
	border-radius: 16px;
	padding: 24px;
	.client-card-top {
		display: flex;
		align-items: center;
		margin-bottom: 17px;
		img {
			width: 74px;
			height: 74px;
			border: 4px solid rgba(#ebf0f0, 0.2);
			border-radius: 11px;
		}
		.cont {
			width: 0;
			flex-grow: 1;
			padding-left: 20px;
			font-weight: 300;
			font-size: 14px;
			line-height: 24px;
			.name {
				font-size: 16px;
				line-height: 24px;
				margin: 0;
			}
			span {
				opacity: 0.5;
			}
		}
	}
	.content {
		font-weight: 300;
		font-size: 14px;
		line-height: 26px;
		color: var(--title);
		opacity: 0.8;
	}
}
.video-section {
	padding-bottom: 75px;
	@include breakpoint(max-lg) {
		padding-bottom: 45px;
	}
}
.video-area {
	position: relative;
	background: rgba(#cfdbd5, 0.15);
	padding: 10px;
	cursor: pointer;
	overflow: hidden;
	min-height: 578px;
	img {
		object-fit: cover;
	}
	img,
	iframe {
		width: 100%;
		border-radius: 10px;
		height: 559.5px;
	}
	iframe {
		display: flex;
	}
	border-radius: 10px;
	.video-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&.video-overlay {
		&::before {
			background: rgba(8, 32, 50, 0.01);
			backdrop-filter: blur(6.5px);
			border-radius: 10px;
			position: absolute;
			content: "";
			inset: 10px;
		}
	}
	@include breakpoint(max-lg) {
		min-height: 450px;
		img,
		iframe {
			height: 420px;
		}
	}
	@include breakpoint(max-lg) {
		min-height: 350px;
		img,
		iframe {
			height: 320px;
		}
	}
}
.choose-section {
	padding: 65px 0 75px;
	@include breakpoint(max-lg) {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	position: relative;
}
.choose-content {
	font-weight: 300;
	line-height: 24px;
	font-size: 16px;
	.title {
		margin-bottom: 24px;
	}
	p {
		margin-bottom: 24px;
	}
	@include breakpoint(max-sm) {
		font-size: 14px;
	}
	position: relative;
}
.choose-shape {
	width: 356px;
	height: 356px;
	left: -191px;
	bottom: 0;
	background: rgba(20, 96, 61, 0.5);
	filter: blur(175px);
	-webkit-filter: blur(175px);
	-moz-filter: blur(175px);
	-ms-filter: blur(175px);
	-o-filter: blur(175px);
	position: absolute;
}
.choose-shape2 {
	position: absolute;
	width: 422px;
	height: 422px;
	background: rgba(20, 96, 61, 0.5);
	filter: blur(175px);
	-webkit-filter: blur(175px);
	-moz-filter: blur(175px);
	-ms-filter: blur(175px);
	-o-filter: blur(175px);
	position: absolute;
	right: 0px;
	top: -100px;
}
.choose-images {
	@include breakpoint(xl) {
		max-width: 510px;
	}
	.img-1,
	.img-2 {
		border: 5px solid rgba(207, 219, 213, 0.15);
		border-radius: 16px;
	}
	.img-1 {
		aspect-ratio: 2;
		object-fit: cover;
	}
	.img-2 {
		aspect-ratio: 1;
		object-fit: cover;
	}
}
.nft-section {
	padding-top: 75px;
	padding-bottom: 134px;
	@include breakpoint(max-lg) {
		padding-top: 40px;
		padding-bottom: 80px;
	}
}
.nft-title {
	margin-bottom: 32px;
	@include breakpoint(md) {
		font-size: 40px;
		font-weight: 500;
		line-height: 48px;
	}
}
.nft-container {
	display: flex;
	flex-wrap: wrap;
	padding: 40px 0;
	border: 1px solid rgba(207, 219, 213, 0.15);
	border-radius: 10px;
	row-gap: 40px;
}
.nft-card {
	width: calc(100% / 4);
	padding-left: 40px;
	padding-right: 40px;
	.title {
		font-weight: 500;
		margin-bottom: 8px;
	}
	.text {
		max-width: 195px;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
	}
	&:not(:last-child) {
		border-right: 1px solid rgba(207, 219, 213, 0.15);
	}
	@include breakpoint(max-lg) {
		width: calc(100% / 2);
		&:nth-child(even) {
			border: none !important;
		}
	}
	@include breakpoint(max-sm) {
		padding-left: 25px;
		padding-right: 25px;
	}
	@media (max-width: 400px) {
		width: calc(100% / 1);
		border: none !important;
	}
}
.footer-bottom {
	border-top: 1px solid rgba(#cfdbd5, 0.15);
	padding: 19px 0;
}
.footer-bottom-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	row-gap: 10px;
	column-gap: 22px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.5);
	.lang-area {
		flex-grow: 1;
	}
	.links {
		display: flex;
		flex-wrap: wrap;
		gap: 22px;
		justify-content: center;
		li {
			a {
				font-weight: 700;
				font-size: 12px;
				line-height: 18px;
				color: var(--white);
				&:hover {
					color: var(--base);
				}
			}
		}
	}
	@include breakpoint(md) {
		justify-content: space-between;
	}
}
.__lang-control {
	position: relative;
	display: inline-flex;
	.form-control {
		-webkit-appearance: none;
		background: transparent;
		width: unset;
		color: var(--white);
		border: none;
		outline: none;
		height: 46px;
		box-shadow: none;
		font-weight: 700;
		font-size: 14px;
		line-height: 24px;
		padding-right: 22px;
		option {
			background: var(--body);
			color: var(--title);
		}
	}
	.globe-icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
	}
}
.lang-area {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 18px;
	@include breakpoint(max-md) {
		justify-content: center;
		width: 100%;
	}
}
.color-theme-icon {
	width: 46px;
	aspect-ratio: 1;
	border: 1px solid rgba(207, 219, 213, 0.15);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: var(--base);
		border-color: var(--base);
	}
	@include breakpoint(max-sm) {
		width: 38px;
	}
}
.footer-widget {
	.title {
		margin-bottom: 18px;
	}
}
.__subscribe-form {
	position: relative;
	.form-control {
		border: 1px solid rgba(#cfdbd5, 0.15);
		border-radius: 24px;
		background: transparent;
		color: var(--white);
		font-weight: 700;
		font-size: 14px;
		line-height: 24px;
		height: 48px;
		padding-left: 20px;
		box-shadow: none;
		padding-right: 45px;
		&:focus {
			border-color: var(--base);
		}
		&::placeholder {
			color: rgba(255, 255, 255, 0.5);
		}
	}
	.icon {
		position: absolute;
		right: 18px;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
	}
}
.mt-57 {
	margin-top: 57px;
	@include breakpoint(max-xl) {
		margin-top: 35px;
	}
}
.social-icons {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	li {
		a {
			width: 46px;
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			&:hover,
			&.active {
				background: rgba(235, 240, 240, 0.1);
			}
			@include breakpoint(max-sm) {
				width: 42px;
			}
		}
	}
}
.footer-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 95px;
	row-gap: 35px;
	.footer-widget {
		&.widget-about {
			width: 100%;
			margin-right: auto;
			@include breakpoint(sm) {
				max-width: 301px;
			}
		}
		&.widget-link {
			width: 100%;
			max-width: 130px;
			ul {
				display: flex;
				flex-direction: column;
				gap: 1px;
				li {
					a {
						font-weight: 700;
						color: rgba(255, 255, 255, 0.5);
						line-height: 34px;
						&:hover {
							color: var(--base);
						}
					}
				}
			}
		}
		color: rgba(#ffffff, 0.5);
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		&.widget-region {
			width: 100%;
			max-width: 305px;
			.text {
				margin-bottom: 25px;
			}
		}
	}
	@include breakpoint(max-xl) {
		column-gap: 40px;
		.footer-widget {
			&.widget-region {
				max-width: 240px;
			}
			&.widget-link {
				max-width: 110px;
				ul {
					li {
						a {
							font-weight: 600;
						}
					}
				}
			}
			&.widget-about {
				max-width: 270px;
				margin: 0;
			}
		}
	}
	@include breakpoint(max-lg) {
		column-gap: 40px;
		.footer-widget {
			&.widget-region {
				max-width: 100%;
			}
		}
	}
	@include breakpoint(max-md) {
		column-gap: 50px;
		justify-content: flex-start;
		.footer-widget {
			&.widget-about {
				max-width: 100%;
			}
			&.widget-link {
				max-width: 130px;
				ul {
					li {
						a {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		column-gap: 30px;
	}
}
.currency-select {
	background: transparent;
	border: 1px solid rgba(#ffffff, 0.15);
	option {
		background: var(--body);
	}
}
.footer-top {
	padding: 66px 0 51px;
}
.banner-section {
	padding: 125px 0 115px;
	@include breakpoint(md) {
		.container {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	@include breakpoint(xxl) {
		.container {
			max-width: 1347;
		}
	}
	@include breakpoint(max-lg) {
		padding: 100px 0;
	}
	@include breakpoint(max-md) {
		padding: 70px 0;
	}
}
.banner-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.creator-card {
		width: 100%;
		max-width: 412px;
	}
	.banner-content {
		width: 100%;
		max-width: 630px;
		.title {
			text-transform: capitalize;
		}
		@include breakpoint(md) {
			.title {
				font-size: 48px;
				line-height: 71px;
				font-weight: 300;
				margin-bottom: 28px;
				strong {
					font-weight: 900;
				}
			}
		}
		.txt {
			max-width: 521px;
			font-weight: 400;
			font-size: 14px;
			line-height: 28px;
			margin-bottom: 28px;
		}
	}
	@include breakpoint(max-xl) {
		.banner-content {
			max-width: 540px;
			.title {
				font-size: 40px;
				line-height: 60px;
				margin-bottom: 15px;
			}
			.txt {
				max-width: 420px;
				margin-bottom: 25px;
				line-height: 1.7;
			}
		}
		.creator-card {
			max-width: 320px;
		}
	}
	@include breakpoint(max-lg) {
		.creator-card {
			max-width: 400px;
			margin-top: 50px;
		}
	}
	@include breakpoint(max-md) {
		.banner-content {
			.title {
				font-weight: 400;
				font-size: 36px;
				line-height: 54px;
				margin-bottom: 15px;
				svg {
					width: 150px;
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		.banner-content {
			.title {
				font-size: 30px;
				line-height: 46px;
				margin-bottom: 15px;
				svg {
					width: 140px;
				}
			}
		}
	}
}
/*Header*/
.header-wrapper {
	padding: 18px 0;
	display: flex;
	align-items: center;
	gap: 8px;
	.logo {
		display: block;
		img {
			height: 80px;
		}
	}
	.search-form {
		width: 0;
		flex-grow: 1;
		max-width: 519px;
		margin-right: 15px;
	}
	.menu-area {
		flex-grow: 1;
		display: flex;
		align-items: center;
		gap: 8px;
		.menu {
			display: flex;
			align-items: center;
			column-gap: 19px;
			margin-left: auto;
			@include breakpoint(lg) {
				margin-right: 15px;
			}
			li {
				a {
					font-weight: 700;
					font-size: 16px;
					line-height: 24px;
					color: rgba(255, 255, 255, 0.5);
					padding: 5px;
					&:hover,
					&.active {
						color: var(--white);
					}
				}
			}
		}
		.cmn-btn {
			padding: 13px 30px;
		}
		@include breakpoint(max-xl) {
			.menu {
				li {
					a {
						font-size: 14px;
						font-weight: 600;
					}
				}
			}
		}
		.close {
			display: none;
		}
		@include breakpoint(max-lg) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 340px;
			height: 100vh;
			background: var(--section);
			z-index: 999;
			flex-direction: column;
			padding: 50px 20px 30px;
			justify-content: flex-start;
			align-items: flex-start;
			transition: all ease 0.4s;
			.close {
				position: absolute;
				right: 15px;
				top: 10px;
				width: unset;
				display: block;
			}
			> * {
				width: 100%;
			}
			.search-form {
				margin: 0;
				flex-grow: 0;
				.form-control {
					height: 46px;
					border-radius: 23px;
				}
			}
			.menu {
				width: 100%;
				flex-direction: column;
				margin: 45px 0;
				gap: 6px;
				li {
					width: 100%;
					text-align: center;
					a {
						display: block;
						font-size: 16px;
						font-weight: 500;
					}
				}
			}
			&:not(.active) {
				transform: translateX(-100%);
			}
		}
	}
	.bar {
		display: none;
		@include breakpoint(max-lg) {
			display: block;
		}
	}
}
.header-btns {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 8px;
	@include breakpoint(max-lg) {
		.cmn-btn {
			flex-grow: 1;
		}
	}
}
header {
	position: sticky;
	top: 0;
	z-index: 999;
	background: var(--body);
	border-bottom: 1px solid rgba(207, 219, 213, 0.1);
	.container {
		@include breakpoint(xl) {
			max-width: 1400px;
		}
	}
}
.__search-form {
	position: relative;
	.form-control {
		border: none;
		border-radius: 20px;
		background: rgba(#ebf0f0, 0.1);
		color: var(--white);
		font-size: 14px;
		line-height: 24px;
		height: 40px;
		padding-right: 20px;
		box-shadow: none;
		padding-left: 45px;
		&::placeholder {
			color: rgba(255, 255, 255, 0.5);
		}
	}
	.left-icon {
		position: absolute;
		left: 18px;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
	}
}
.rounded-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	aspect-ratio: 1;
	border: 1px solid rgba(207, 219, 213, 0.15);
	border-radius: 23px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.author-img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	aspect-ratio: 1;
	overflow: hidden;
	border-radius: 50%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.layout-title {
	margin-bottom: 30px;
	@include breakpoint(max-md) {
		margin-bottom: 20px;
	}
}
.pt-40 {
	padding-top: 40px;
}
.mb-40 {
	margin-bottom: 40px;
}
.dashboard-card {
	padding: 15px 24px;
	position: relative;
	color: var(--title);
	border: 1px solid rgba(#cfdbd5, 0.15);
	border-radius: 12px;
	.arrow {
		position: absolute;
		right: 24px;
		top: 15px;
	}
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	.subtxt {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 10px;
		display: block;
		color: var(--text);
	}
	.title {
		margin: 0;
	}
}
.__dashboard-article {
	max-width: 980px;
	.creator-card {
		.cmn-btn {
			@include breakpoint(sm) {
				height: 48px;
			}
		}
	}
}
.__dashboard-aside {
	width: calc(100% - 980px);
}
.right-overflow {
	@media (min-width: 480px) {
		padding-right: 105px;
		overflow: hidden;
		.slick-list {
			overflow: visible;
		}
	}
}
.__card {
	background: transparent;
	border-radius: 16px;
	.card-header {
		border-radius: 16px 16px 0 0;
		border-color: rgba(#cfdbd5, 0.15);
		padding: 15px;
		@include breakpoint(md) {
			padding: 22px;
		}
		.card-title {
			margin: 0;
		}
	}
	.card-body {
		border-radius: 0 0 16px 16px;
		border-color: rgba(#cfdbd5, 0.15);
		padding: 15px;
		@include breakpoint(md) {
			padding: 22px;
		}
	}
	border-color: rgba(#cfdbd5, 0.15);
}
.activity-list {
	display: flex;
	flex-direction: column;
	gap: 23px;
	li {
		border-radius: 12px;
		background: var(--section);
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: rgba(255, 255, 255, 0.5);
		font-weight: 300;
		font-size: 12px;
		line-height: 24px;
		padding: 13px 18px 9px;
		.title {
			width: 100px;
			flex-grow: 1;
		}
		h6 {
			font-size: 14px;
			line-height: 24px;
			margin: 0;
			span {
				color: var(--text);
			}
		}
	}
}
.radial-chart {
	path[stroke="rgba(242,242,242,0.85)"] {
		stroke: rgba(242, 242, 242, 0.15);
		stroke-width: 4px;
	}
	path[stroke-linecap="butt"] {
		stroke-width: 4px;
	}
	text {
		fill: #fff;
	}
}
.chart-info {
	li {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		&:not(:last-child) {
			border-bottom: 1px dashed rgba(207, 219, 213, 0.15);
		}
		padding: 11px 0;
		span:last-child {
			color: var(--title);
			font-weight: 700;
		}
		position: relative;
		.indicator {
			border-radius: 8px;
			width: 12px;
			height: 12px;
			position: absolute;
			background: var(--base);
			left: 0;
			top: 17px;
		}
		padding-left: 20px;
	}
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-80 {
	padding-bottom: 80px;
}
.__property-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	.__property-card {
		background: var(--section);
		border-radius: 12px;
		padding: 18px;
		.subtext {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 12px;
		}
		.value {
			font-weight: 800;
			font-size: 18px;
			line-height: 24px;
			margin: 0;
		}
		width: calc((100% / 3) - 16px);
	}
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			gap: 12px;
			.__property-card {
				width: calc((100% / 3) - 8px);
				padding: 18px 12px;
				.value {
					font-size: 16px;
					font-weight: 700;
				}
			}
		}
	}
	@include breakpoint(max-md) {
		gap: 12px;
		.__property-card {
			width: calc((100% / 3) - 8px);
			padding: 18px 12px;
			.value {
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
	@include breakpoint(max-sm) {
		gap: 12px;
		.__property-card {
			width: calc((100% / 2) - 8px);
			padding: 18px 12px;
			.value {
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
}
.__filter {
	display: flex;
	flex-wrap: wrap;
	column-gap: 22px;
	row-gap: 12px;
	li {
		border: 1px solid rgba(207, 219, 213, 0.15);
		border-radius: 18px;
		padding: 9px 22px;
		font-weight: 700;
		font-size: 12px;
		line-height: 18px;
		transition: all ease 0.3s;
		cursor: pointer;
		&.active {
			background: var(--base);
			color: var(--white);
		}
	}
	margin-bottom: 24px;
	padding-top: 2px;
}
.store-banner-section {
	padding: 59px 0 26px;
	@include breakpoint(max-lg) {
		background-size: contain !important;
		background-position: top center !important;
		padding-top: 40px;
	}
}
.store-img {
	width: 100%;
	height: 100%;
	border-radius: 24.59px;
	object-fit: cover;
}
.store-cont-top {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	.secondary-btn {
		&:hover {
			background: var(--btn-hover);
		}
	}
	.cmn-btn {
		padding: 9.5px 22px;
	}
	margin-bottom: 36px;
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			gap: 10px;
			.cmn-btn {
				font-size: 12px;
			}
		}
	}
	@include breakpoint(max-lg) {
		margin-bottom: 25px;
	}
	@include breakpoint(max-sm) {
		gap: 10px;
		.cmn-btn {
			font-size: 12px;
		}
	}
}
@include breakpoint(lg) {
	.p-lg-32 {
		padding: 32px !important;
	}
}
.store-content {
	margin-bottom: 37px;
	.title {
		margin-bottom: 20px;
	}
	.subtitle {
		margin-bottom: 33px;
	}
	.subtext {
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
		color: rgba(255, 255, 255, 0.5);
		margin-bottom: 33px;
	}
	@include breakpoint(max-sm) {
		margin-bottom: 32px;
		.title {
			margin-bottom: 15px;
		}
	}
	@include breakpoint(max-lg) {
		margin-bottom: 25px;
	}
}
.__p-card {
	background: var(--section);
	border-radius: 12px;
	padding: 21px 18px;
	.subtext {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 12px;
	}
	.value {
		font-weight: 800;
		font-size: 18px;
		line-height: 24px;
		margin: 0;
		span {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: rgba(255, 255, 255, 0.5);
		}
	}
}
.__btns-grid {
	.cmn-btn {
		width: 200px;
		flex-grow: 1;
	}
}
.btn-danger {
	background: #dd333e;
}
.stats-section {
	padding: 60px 0;
	@include breakpoint(max-lg) {
		padding: 40px 0;
	}
}
.stats-body {
	padding-bottom: 100px;
	@include breakpoint(max-lg) {
		padding-bottom: 70px;
	}
}
.__stat-sidebar-title {
	margin-bottom: 42px;
	@include breakpoint(max-lg) {
		margin-bottom: 20px;
	}
}
.accordion-card {
	border: 1px solid rgba(#cfdbd5, 0.15);
	border-radius: 14px;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
	.title {
		font-weight: 700;
		font-size: 14px;
		line-height: 24px;
		color: var(--title);
		padding: 18px 24px;
		cursor: pointer;
	}
	.accordion-card-body {
		overflow: hidden;
		height: 0;
		.accordion-card-body-inner {
			padding: 18px 24px;
			padding-top: 0;
		}
	}
	position: relative;
	.right-icon {
		top: 16px;
		right: 21px;
		position: absolute;
	}
	&.open {
		.accordion-card-body {
			height: 100%;
			animation: fadeInDown 0.5s;
		}
		.right-icon {
			transform: rotate(-180deg);
		}
	}
}
.stats-btn-wrapper {
	display: flex;
	flex-wrap: wrap;
	column-gap: 6px;
	row-gap: 8px;
	a {
		font-weight: 700;
		font-size: 12px;
		line-height: 18px;
		padding: 9px 23px;
		border-radius: 18px;
		color: var(--white);
		border: 1px solid rgba(207, 219, 213, 0.15);
		&:hover {
			background: var(--base);
			border-color: var(--base);
		}
	}
}
.check-wrapper {
	.form--check {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		.check-icon {
			content: "";
			width: 20px;
			height: 20px;
			border: 1px solid rgba(207, 219, 213, 0.15);
			border-radius: 4px;
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 16px;
				path {
					fill: #fff;
				}
			}
		}
		padding-left: 26px;
		width: 100%;
		input {
			opacity: 0;
			width: 1px;
		}
		font-weight: 700;
		font-size: 12px;
		line-height: 24px;
		color: rgba(255, 255, 255, 0.5);
		.amount {
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			padding: 0 9px;
			border: 1px solid rgba(207, 219, 213, 0.15);
			border-radius: 12px;
		}
		&.active {
			color: var(--title);
			.check-icon {
				background: var(--base);
				border-color: var(--base);
			}
			.amount {
				background: var(--base);
				color: var(--white);
			}
		}
	}
}
.range-slider {
	.range-slider__thumb {
		background: var(--body) !important;
		border: 3px solid var(--base);
		width: 12px !important;
		height: 12px !important;
	}
	background: rgba(#ebf0f0, 0.1) !important;
	height: 4px !important;
	.range-slider__range {
		background: var(--base) !important;
	}
}
.price-range {
	margin-top: 15px;
}
.chart-range-amount {
	font-weight: 700;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	margin-top: 20px;
}
.bar-data {
	display: flex;
	gap: 4px;
	justify-content: space-between;
	align-items: flex-end;
	span {
		width: 4px;
		max-width: 5px;
		flex-grow: 1;
		background: #ebf0f0;
		border-radius: 1px;
		opacity: 0.1;
		&.active {
			background: var(--base);
			opacity: 1;
		}
	}
}
.listed-property {
	padding: 60px 0;
	@include breakpoint(max-lg) {
		padding: 50px 0;
	}
}
.__table {
	border-collapse: separate;
	border-spacing: 0;
	thead {
		tr {
			th {
				background: var(--section);
				color: var(--title);
				border: none;
				font-weight: 700;
				font-size: 14px;
				line-height: 24px;
				padding: 12.5px 14.49px 9.5px;
				text-transform: capitalize;
				svg {
					margin-left: 6px;
				}
				&:first-child {
					border-radius: 12px 0 0 12px;
					-webkit-border-radius: 12px 0 0 12px;
					-moz-border-radius: 12px 0 0 12px;
				}
				&:last-child {
					border-radius: 0 12px 12px 0;
					-webkit-border-radius: 0 12px 12px 0;
					-moz-border-radius: 0 12px 12px 0;
				}
				div {
					display: inline-flex;
					cursor: pointer;
				}
			}
		}
	}
	tbody {
		tr {
			padding-top: 12px;
			td {
				vertical-align: middle;
				font-weight: 700;
				font-size: 14px;
				line-height: 24px;
				color: var(--title);
				border: none;
				padding: 12px;
				border-top: 1px solid rgba(#cfdbd5, 0.15);
				border-bottom: 1px solid rgba(#cfdbd5, 0.15);
				&:first-child {
					border-left: 1px solid rgba(#cfdbd5, 0.15);
					border-radius: 12px 0 0 12px !important;
					-webkit-border-radius: 12px 0 0 12px;
					-moz-border-radius: 12px 0 0 12px;
				}
				&:last-child {
					border-right: 1px solid rgba(#cfdbd5, 0.15);
					border-radius: 0 12px 12px 0;
					-webkit-border-radius: 0 12px 12px 0;
					-moz-border-radius: 0 12px 12px 0;
				}
				@include breakpoint(max-lg) {
					font-weight: 400;
				}
			}
		}
	}
}
.word-nobreak {
	white-space: nowrap;
}
.__img-container {
	display: flex;
	flex-wrap: nowrap;
	gap: 10px;
	img {
		width: 98px;
		border-radius: 10px;
	}
}
.__status {
	// padding: 6.5px 17px 6.5px 40px;
	padding: 8px 17px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 106px;
	font-size: 12px;
	line-height: 18px;
	color: #ebf0f0;
	.dots {
	}
}

a:focus ~ .__dropdown-menu,
.__dropdown-menu.active:hover {
	opacity: 1;
	visibility: visible;
	display: block;
}
.__dropdown-menu {
	background: var(--section);
	border-radius: 6px;
	width: 120px;
	right: 0;
	border: 1px solid rgba(#fff, 0.1);
	li {
		a {
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			color: var(--title);
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
}
.__listed-props-title {
	margin-bottom: 28px;
	gap: 10px;
	@include breakpoint(max-md) {
		margin-bottom: 20px;
	}
}
